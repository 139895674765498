<template>
  <div class="home">
    <v-container fluid class="bg-white mt-65">
      <div class="d-flex pb-4">
        <h1 class="primetime">Vehicles</h1>

        <div class="ml-auto mr-3 d-flex" cols="12" md="2">
          <v-btn
            class="ml-auto mt-1 mr-5"
            @click="exportVehicle"
            color="primary"
            :loading="downloading"
            >export
          </v-btn>
          <manage
            ref="manage"
            @dataUpdated="getData()"
            :show="formShow"
            :custom="true"
            :newz="permissions['vehicles.store'] === true"
            :editing="true"
            @cancelForm="formShow = null"
          />
          <v-btn
            v-if="roleId !== 8"
            class="mt-1 mr-2"
            color="primary"
            to="/loading-vehicle"
            >loading of Vehicles
          </v-btn>
          <v-btn
            v-if="roleId !== 8"
            class="mt-1 mr-2"
            color="primary"
            to="/weights"
            >Vehicle Weight
          </v-btn>
          <v-btn
            v-if="roleId !== 8 && roleId !== 4"
            class="mt-1"
            to="/notes"
            color="primary"
          >
            notes
            <p class="complain-counter">{{ notes_count }}</p>
          </v-btn>
          <handover
            ref="handover"
            @dataUpdated="search()"
            :show="hand_over.dialog"
            :id="hand_over.vehicle_id"
            :vin="hand_over.vin"
            @cancelForm="hand_over.dialog = null"
          />
        </div>
      </div>
      <v-row>
        <v-col cols="12" md="10" class="pb-0 pt-0">
          <div>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  v-model="filters.vehicle_global_search"
                  label="Global Search"
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-btn :loading="filtering" color="primary" @click="search"
                  >Search
                </v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <h5>
                  Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                  {{ this.showingTo ? this.showingTo : "0" }} of
                  {{ this.count ? this.count : "0" }}
                </h5>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="2"> </v-col>
      </v-row>
      <confirmation
        :confirming="deletingId"
        @confirmed="remove"
        :show="deleteConfirmationShow"
        @canceled="deleteConfirmationShow = false"
      />
      <vue-content-loading :width="300" :height="100" v-if="loading">
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>

      <v-simple-table ref="v-simple-table" v-if="vehicles.data && !loading">
        <template v-slot:default>
          <thead>
            <tr>
              <!-- <th class="text-left">#</th> -->
              <th class="text-left">Photo</th>
              <th class="text-left">Total Photos</th>
              <th class="text-left" style="min-width: 90px">HAT</th>
              <!-- <th v-if="permissions['exports.store']" class="text-left">Add</th> -->
              <th class="text-left date">REQ DATE</th>
              <th class="text-left date">DELY DATE</th>
              <th class="text-left">Days</th>
              <th
                v-if="currentSortDir == 'asc' && currentSort == 'year'"
                class="text-left sortable"
                @click="Sort('year')"
              >
                Year
                <v-icon>mdi-sort-ascending</v-icon>
              </th>
              <th
                v-else-if="currentSortDir == 'desc' && currentSort == 'year'"
                class="text-left sortable"
                @click="Sort('year')"
              >
                Year
                <v-icon>mdi-sort-descending</v-icon>
              </th>
              <th v-else class="text-left sortable" @click="Sort('year')">
                Year
              </th>
              <th
                v-if="currentSortDir == 'asc' && currentSort == 'make'"
                class="text-left sortable"
                @click="Sort('make')"
              >
                Make
                <v-icon>mdi-sort-ascending</v-icon>
              </th>
              <th
                v-else-if="currentSortDir == 'desc' && currentSort == 'make'"
                class="text-left sortable"
                @click="Sort('make')"
              >
                Make
                <v-icon>mdi-sort-descending</v-icon>
              </th>
              <th v-else class="text-left sortable" @click="Sort('make')">
                Make
              </th>
              <!--  -->
              <th
                v-if="currentSortDir == 'asc' && currentSort == 'model'"
                class="text-left sortable"
                @click="Sort('model')"
              >
                Model
                <v-icon>mdi-sort-ascending</v-icon>
              </th>
              <th
                v-else-if="currentSortDir == 'desc' && currentSort == 'model'"
                class="text-left sortable"
                @click="Sort('model')"
              >
                Model
                <v-icon>mdi-sort-descending</v-icon>
              </th>
              <th v-else class="text-left sortable" @click="Sort('model')">
                Model
              </th>
              <!--  -->
              <th class="text-left">Color</th>
              <!--  -->
              <th
                v-if="currentSortDir == 'asc' && currentSort == 'vin'"
                class="text-left sortable"
                @click="Sort('vin')"
              >
                Vin
                <v-icon>mdi-sort-ascending</v-icon>
              </th>
              <th
                v-else-if="currentSortDir == 'desc' && currentSort == 'vin'"
                class="text-left sortable"
                @click="Sort('vin')"
              >
                Vin
                <v-icon>mdi-sort-descending</v-icon>
              </th>
              <th v-else class="text-left sortable" @click="Sort('vin')">
                Vin
              </th>
              <!--  -->
              <th
                v-if="currentSortDir == 'asc' && currentSort == 'lot_number'"
                class="text-left sortable"
                @click="Sort('lot_number')"
              >
                Lot no
                <v-icon>mdi-sort-ascending</v-icon>
              </th>
              <th
                v-else-if="
                  currentSortDir == 'desc' && currentSort == 'lot_number'
                "
                class="text-left sortable"
                @click="Sort('lot_number')"
              >
                Lot no
                <v-icon>mdi-sort-descending</v-icon>
              </th>
              <th v-else class="text-left sortable" @click="Sort('lot_number')">
                Lot no
              </th>
              <!--  -->
              <th class="text-left">Buyer Id</th>
              <th class="text-left">Keys</th>

              <th class="text-left">Vehicle Type</th>
              <th class="text-left">Title</th>
              <th class="text-left date">Title Received Date</th>

              <th class="text-left">Loc</th>
              <th class="text-left" v-if="yardShow">Yard</th>
              <th class="text-left">Hybrid</th>
              <th class="text-left">Status</th>
              <th class="text-left">Container No</th>

              <th class="text-left date">Eta Date</th>
              <!--  -->
              <th
                v-if="
                  currentSortDir == 'asc' && currentSort == 'customer_user_id'
                "
                class="text-left sortable"
                @click="Sort('customer_user_id')"
              >
                Customer Name
                <v-icon>mdi-sort-ascending</v-icon>
              </th>
              <th
                v-else-if="
                  currentSortDir == 'desc' && currentSort == 'customer_user_id'
                "
                class="text-left sortable"
                @click="Sort('customer_user_id')"
              >
                Customer Name
                <v-icon>mdi-sort-descending</v-icon>
              </th>
              <th
                v-else
                class="text-left sortable"
                @click="Sort('customer_user_id')"
              >
                Customer Name
              </th>
              <!--  -->
              <th class="text-left">Loading Type</th>

              <th class="text-left date">Created Date</th>
              <th class="text-left" v-if="roleId !== 8 && roleId !== 4">
                Note
              </th>
              <th class="text-left">Search</th>
              <th class="text-left">Document</th>
              <th class="text-left">Invoice</th>
              <th style="min-width: 228px" class="text-left">Actions</th>
            </tr>
            <!-- <tr v-if="permissions['vehicles.store'] == false"></tr> -->
            <tr>
              <!-- <td></td> -->
              <td></td>
              <td></td>
              <td class="py-3">
                <v-text-field
                  v-model="filters.hat_number"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.towing_request_date"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.deliver_date"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td></td>
              <td>
                <v-text-field
                  v-model="filters.year"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.make"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>

              <td>
                <v-text-field
                  v-model="filters.model"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.color"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.vin"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>

              <td>
                <v-text-field
                  v-model="filters.lot_no"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  v-model="filters.buyer_id"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>
              <td>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="keysItem"
                  v-model="filters.keys"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>

              <td>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="vehicleItem"
                  v-model="filters.vehicle_type"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>
              <td>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="titlesItem"
                  v-model="filters.title"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>
              <td>
                <v-text-field
                  v-model="filters.title_received_date"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>

              <td>
                <v-select
                  item-text="name"
                  item-value="id"
                  :items="locationsItem"
                  v-model="filters.location"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>

              <td v-if="yardShow">
                <v-select
                  item-text="name"
                  item-value="id"
                  :items="yardsItem"
                  v-model="filters.yard_id"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>

              <td>
                <v-select
                  item-text="name"
                  item-value="id"
                  :items="hybridsItem"
                  v-model="filters.hybrid"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>

              <td>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="statusItem"
                  v-model="filters.status"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>
              <td>
                <v-text-field
                  v-model="filters.container_no"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>

              <td>
                <v-text-field
                  v-model="filters.eta"
                  outlined
                  dense
                  hide-details
                  @keypress.enter="manualSearch"
                ></v-text-field>
              </td>

              <td>
                <!--<v-autocomplete-->
                <!--dense-->
                <!--outlined-->
                <!--v-model="filters.customer_user_id"-->
                <!--:items="customersItem"-->
                <!--item-text="customer_name"-->
                <!--item-value="user_id"-->
                <!--:search-input.sync="customarUserId"-->
                <!--autocomplete="none"-->
                <!--hide-no-data-->
                <!--hide-selected-->
                <!--:loading="loading2"-->
                <!--style="width:200px;margin-top: 25px;"-->
                <!--&gt;</v-autocomplete>-->

                <v-autocomplete
                  dense
                  outlined
                  v-model="filters.customer_user_id"
                  :items="customersItem"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="customarUserId"
                  :loading="loading2"
                  style="width: 200px; margin-top: 25px"
                  no-filter
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </td>

              <td>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="loadingTypesItem"
                  v-model="filters.loading_type"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>

              <td></td>
              <td>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="notesItem"
                  v-model="filters.notes_status"
                  @change="manualSearch"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </td>
              <td></td>
              <td></td>
              <td v-if="roleId !== 8 && roleId !== 4"></td>
              <td>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  :loading="filtering"
                  @click="search"
                >
                  <v-icon dark>mdi-clipboard-text-search</v-icon>
                </v-btn>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  :loading="filtering"
                  @click="clearFilter"
                  v-if="isFilter"
                >
                  <v-icon dark>mdi-close-octagon</v-icon>
                </v-btn>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in vehicles.data"
              v-bind:key="index"
              :style="{ background: item.row_color }"
            >
              <!-- <td>{{ item.id }}</td> -->
              <td>
                <manage-image
                  :lot="item.total_photos"
                  :text="item.photo"
                  :id="item.id"
                />
              </td>
              <td>{{ item.total_photos }}</td>
              <td>{{ item.hat_number }}</td>
              <!--<td v-if="permissions['exports.store']">
                <button
                  v-if="item.status_name === 'ON HAND'"
                  @click="addToCart(item)"
                >
                  <v-icon
                    v-if="checking.includes(item.id)"
                    style="
                      color: green;
                      transform: rotate(45deg);
                      cursor: pointer;
                    "
                    >mdi-close-thick
                  </v-icon>
                  <v-icon
                    v-else
                    style="transform: rotate(45deg); cursor: pointer"
                    >mdi-close-thick
                  </v-icon>
                </button>
                <div v-else>
                  <v-icon>mdi-minus</v-icon>
                </div>
              </td> -->
              <td>{{ item.towing_request_date }}</td>
              <td>{{ item.deliver_date }}</td>
              <td>{{ item.age }}</td>
              <!-- <td></td> -->
              <td>{{ item.year }}</td>
              <td>{{ item.make }}</td>

              <td>{{ item.model }}</td>
              <td>{{ item.color }}</td>
              <td>
                <router-link
                  target="_blank"
                  style="text-decoration: none"
                  :to="`/vehicles/${item.id}`"
                >
                  {{ item.vin }}
                </router-link>
              </td>

              <td>
                <manage-details
                  :lot="true"
                  :text="item.lot_number"
                  :id="item.id"
                />
              </td>
              <td>{{ item.buyer_id }}</td>
              <td>{{ item.keys_name }}</td>
              <td>{{ item.vehicle_type }}</td>
              <td>{{ item.title_type_name }}</td>
              <td>{{ item.title_received_date }}</td>
              <td>{{ item.location }}</td>
              <td v-if="yardShow">{{ item.yard_name }}</td>
              <td>{{ item.hybrid == 1 ? 'Yes' : item.hybrid == 2 ?  'No' : '' }}</td>
              <td>{{ item.status_name }}</td>
              <td>
                <a
                  v-if="item.container_tracking_url"
                  target="_blank"
                  :href="`${item.container_tracking_url}`"
                >
                  {{ item.container_number }}
                </a>
                <span v-else>{{ item.container_number }}</span>
              </td>
              <td>{{ item.eta }}</td>
              <td>{{ item.customer_name }}</td>
              <!-- <td>{{ item.customer_user_id }}</td> -->
              <td>{{ item.loading_type }}</td>
              <td>{{ item.created_at }}</td>
              <td v-if="roleId !== 8 && roleId !== 4">
                <notes-modal
                  :text="item.note_status.label"
                  :id="item.id"
                  :vehicle_detail="{
                    customer_name: item.customer_name,
                    vin: item.vin,
                    lot_number: item.lot_number,
                  }"
                  :status="item.note_status.value"
                  :color="item.note_status.class"
                  @notestoggle="notesShow = !notesShow"
                  :show="notesShow"
                  @dataupdate="getData()"
                />
              </td>

              <td>
                <a
                  target="_blank"
                  :href="`https://www.google.com/search?tbm=isch&as_q=${item.year}++${item.model}++${item.make}++${item.color}`"
                >
                  <v-icon>mdi-search</v-icon>
                  search
                </a>
              </td>
              <td>
                <div v-for="(invoice, i) in item.vehicle_documents" :key="i">
                  <v-btn small class="ma-1" @click="invoiceUrl(invoice.url)"
                    >{{ invoice.label }}
                  </v-btn>
                </div>
              </td>
              <td>
                <div v-for="(invoice, i) in item.invoice_photos" :key="i">
                  <v-btn small class="ma-1" @click="invoiceUrl(invoice.url)"
                    >{{ invoice.label }}
                  </v-btn>
                </div>
              </td>
              <td>
                <div v-if="permissions['vehicles.view'] == false"></div>
                <v-btn v-else text target="_blank" :to="`/vehicles/${item.id}`">
                  <v-icon>mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn
                  v-if="hasEditPermission(item.status)"
                  text
                  @click="edit(item.id)"
                  color="blue"
                >
                  <v-icon>mdi-file-edit</v-icon>
                </v-btn>
                <div v-if="permissions['vehicles.destroy'] == false"></div>
                <v-btn v-else text @click="destroy(item.id)" color="red">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  text
                  @click="editHandOver(item.id, item.vin)"
                  v-if="item.status === 6"
                >
                  Hand Over
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row justify="center" style="margin: 0">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              class="my-4"
              :total-visible="7"
              circle
              @input="getData(pagination.current, filtersUrl())"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!--<manageedit @dataUpdated="getData()" :id="editId" :show="formShowEdit" :editing="true" @cancelForm="formShow = false"/>-->
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import NotesModal from "@/components/vehicle/NotesModal.vue";
import Api from "@/services/Api";
import Manage from "@/views/vehicles/Manage.vue";
import ManageDetails from "@/views/vehicles/ManageDetails.vue";
import Handover from "@/views/vehicles/ManageHandedOver.vue";
// import Manageedit from "@/views/vehicles/Manageedit.vue";
import ManageImage from "@/views/vehicles/ManageImage.vue";
import { VueContentLoading } from "vue-content-loading";
import { mapGetters } from "vuex";
import downloadFile from "@/helpers/downloadFile";

export default {
  name: "Vehicles",
  components: {
    Confirmation,
    Manage,
    VueContentLoading,
    NotesModal,
    ManageDetails,
    Handover,
    ManageImage,
  },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      currentSort: " ",
      currentSortDir: "asc",
      currentUrl: null,
      notesShow: false,
      vehicleGallar: false,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      editId2: null,
      deletingId: null,
      formShow: false,
      formShow2: false,
      dialog: false,
      hand_over: {
        dialog: false,
        vehicle_id: null,
        vin: "",
        date: null,
      },
      items: [],
      index: null,
      // formShowEdit: false,
      deleteConfirmationShow: false,
      vehicles: {},
      checking: [],

      customarUserId: "",
      pagination: {
        current: 1,
        total: 0,
      },
      keysItem: [
        { title: "", id: "" },
        { title: "Yes", id: "1" },
        { title: "No", id: "0" },
      ],
      hybridsItem: [
        { name: "", id: "" },
        { name: "Yes", id: "1" },
        { name: "No", id: "2" },
      ],
      locationsItem: [],
      yardsItem: [],
      titlesItem: [
        { title: "", id: "" },
        { title: "NO TITLE", id: "0" },
        { title: "EXPORTABLE", id: 1 },
        { title: "PENDING", id: 2 },
        { title: "BOS", id: 3 },
        { title: "LIEN", id: 4 },
        { title: "MV907", id: 5 },
        { title: "REJECTED", id: 6 },
      ],
      statusItem: [
        { title: "", id: "" },
        { title: "ON HAND", id: 1 },
        { title: "MANIFEST", id: 2 },
        { title: "ON THE WAY", id: 3 },
        { title: "SHIPPED", id: 4 },
        { title: "PICKED UP", id: 5 },
        { title: "ARRIVED", id: 6 },
        { title: "HANDED OVER", id: 7 },
      ],
      claimStatus: [
        { title: "" },
        { title: "REJECTED", id: "0" },
        { title: "APPROVED", id: 1 },
        { title: "PENDING", id: 10 },
      ],
      damageClaim: [
        { title: "", id: "" },
        { title: "YES", id: 1 },
        { title: "NO", id: "0" },
      ],
      customersItem: [],
      loadingTypesItem: [
        { title: "", id: "" },
        { title: "Full", id: 1 },
        { title: "Mix", id: 2 },
      ],
      notesItem: [
        { title: "", id: "" },
        { title: "Open", id: 2 },
        { title: "Closed", id: 1 },
      ],
      vehicleItem: [
        { title: "", id: "" },
        { title: "Sedan", id: "Sedan" },
        { title: "Van", id: "Van" },
        { title: "Pickup", id: "Pickup" },
        { title: "Truck", id: "Truck" },
        { title: "Mortorcycle", id: "Mortorcycle" },
      ],
      loading: false,
      filters: {},
      filtering: false,
      loading2: false,
      count: 0,
      showingFrom: 0,
      showingTo: 0,
      userId: JSON.parse(localStorage.getItem("user")).id,
      downloading: false,
      yardShow : true,
    };
  },

  computed: {
    ...mapGetters(["cart"]),
  },

  methods: {
    addToCart(item) {
      let items = localStorage.getItem("asl_checkout");
      if (items) {
        items = JSON.parse(items);
      } else {
        items = {};
      }
      //  cart item logic

      if (items.hasOwnProperty(item.location_id)) {
        // items[item.location_id].forEach((e) => {
        //   if (e.id == item.id) {
        //     return;
        //   } else {
        //     items[item.location_id].push(item);
        //   }
        // });

        let ifExits = items[item.location_id].find((el) => el.id === item.id);
        if (!ifExits) {
          items[item.location_id].push(item);
        }
      } else {
        items[item.location_id] = [item];
      }
      localStorage.setItem("asl_checkout", JSON.stringify(items));
      this.$store.commit("auth/addToCart", JSON.stringify(items));
    },

    hasEditPermission(status) {
      if (this.roleId === 8) {
        return (
          this.permissions["vehicles.update"] && (status === 3 || status === 1)
        );
      } else {
        return this.permissions["vehicles.update"];
      }
    },

    Sort(currentsort) {
      this.currentSortDir =
        this.currentSortDir === "asc" && this.currentSort === currentsort
          ? "desc"
          : "asc";
      this.currentSort = currentsort;
      this.loading = true;

      this.filters.page = this.pagination.current;
      this.filters.order_by_column = currentsort;
      this.filters.order_by = this.currentSortDir;

      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + totalSortFilterQuery;
      window.history.pushState({}, "", finalUrl);

      Api.get(`/vehicles?${totalSortFilterQuery}`)
        .then((res) => {
          this.vehicles = res.data;
          this.yardShow = res?.data?.yard_show;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status == 401) {
            this.$toastr.e(err.response.data.status);
          } else {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!currentsort) {
        var currentsort = this.currentSort;
      }
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      if (!page) {
        var page = this.pagination.current;
      }
      if (searchQuery) {
        var searchQuery = `&${searchQuery}`;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + this.filtersUrl();
      window.history.pushState({}, "", finalUrl);

      // Api.get(`/vehicles?page=${page}${searchQuery}`)
      Api.get(`/vehicles?${finalSearchQuery}`)
        .then((res) => {
          res.data.data.map((i, val) => {
            this.items.push(i.photo_urls);
          });

          this.count = res.data.total;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.vehicles = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
          this.hand_over.dialog = false;
          this.getApi = false;
          this.yardShow = res?.data?.yard_show;
          this.yardsItem = res?.data?.yard_items?.data;
          this.$store.commit("counter/setNotesCount", res.data.notes_count);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.loading = false;
            this.$toastr.e(err.response.data.status);
          } else {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },

    getDataWithFilter(searchQuery) {
      Api.get(`/vehicles?${searchQuery}`)
        .then((res) => {
          res.data.data.map((i, val) => {
            this.items.push(i.photo_urls);
          });

          this.count = res.data.total;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.vehicles = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
          this.hand_over.dialog = false;
          this.getApi = false;
          this.yardShow = res?.data?.yard_show;
          this.yardsItem = res?.data?.yard_items?.data;
          this.$store.commit("counter/setNotesCount", res.data.notes_count);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.loading = false;
            this.$toastr.e(err.response.data.status);
          } else {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    invoiceUrl(e) {
      window.open(e, "_blank");
    },
    loadingManage(value) {
      this.downloading = value;
    },
    exportVehicle() {
      this.loadingManage(true);
      let url =
        Api.defaults.baseURL +
        `/vehicles/export-excel?auth_user_id=${this.userId}`;
      if (this.filtersUrl()) {
        var searchQuery = `?${this.filtersUrl()}`;
        url =
          Api.defaults.baseURL +
          `/vehicles/export-excel${searchQuery}&auth_user_id=${this.userId}`;
      }
      // window.location = url;
      downloadFile(url, "vehicle_export", ".xls", this.loadingManage);
    },

    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
    },

    manualSearch() {
      this.filters.page = 1;
      this.search();
    },

    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    remove() {
      Api.delete(`/vehicles/${this.deletingId}`)
        .then((res) => {
          this.getData();
          // this.$toastr.s(res.message);
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
      // this.formShowEdit = false;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
      // this.formShowEdit = true;
    },
    editHandOver(id, vin) {
      this.hand_over.vehicle_id = id;
      this.hand_over.vin = vin;
      this.hand_over.dialog = true;
    },
    detail(id) {
      this.formShow2 = true;
      this.$refs.manage2.edit(id);
      // this.formShowEdit = true;
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
    selectCustomerId() {
      if (Object.keys(this.$route.query).length > 0) {
        if (
          this.$route.query.customer_user_id &&
          this.vehicles.data[0].customer_name
        ) {
          Api.get(
            `/customers-item?status=1&customer_name=${this.vehicles.data[0].customer_name}`
          )
            .then((res) => {
              this.loading2 = false;
              this.customersItem = res.data.data;
            })
            .catch((err) => {
              if (err.response.status !== 401) {
                this.$toastr.e("Failed to load data!" + err);
                this.loading2 = false;
              }
            });
        }
      }
    },
    dismissHandOverForm() {
      this.hand_over.dialog = false;
      this.hand_over.vehicle_id = null;
      this.hand_over.date = "";
    },

    cusotmarAutoCompleteHandelar(newVal) {
      this.loading2 = true;
      Api.get(`/customers-item?status=1&customer_name=${newVal}`)
        .then((res) => {
          this.loading2 = false;
          this.customersItem = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading2 = false;
          }
        });
    },

    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
  },
  mounted() {
    this.locationsItem = this.$store.state.auth.locations;
    this.yardsItem = this.$store.state.auth.yards;

    if (localStorage.getItem("asl_checkout") !== null) {
      let check = Object.values(
        JSON.parse(localStorage.getItem("asl_checkout"))
      ).map(Object.values);
      check.map((e) => {
        e.map((j) => {
          return this.checking.push(j.id);
        });
      });
    }

    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.mode && this.$route.query.mode === "openModal") {
        this.formShow = true;
      }
      if (this.$route.query.title) {
        this.$route.query.title = parseInt(this.$route.query.title);
      }
      if (this.$route.query.status) {
        this.$route.query.status = this.$route.query.status;
      }
      if (this.$route.query.location) {
        this.$route.query.location = parseInt(this.$route.query.location);
      }

      this.filters = this.$route.query;

      if (this.filters.order_by_column) {
        this.currentSort = this.filters.order_by_column;
      }
      if (this.filters.order_by) {
        this.currentSortDir = this.filters.order_by;
      }

      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.getData();
    }
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length) {
        return true;
      } else {
        return false;
      }
    },
    notes_count() {
      return this.$store.getters["counter/counter"].notes_count;
    },
  },
  watch: {
    customarUserId(newVal) {
      // if (newVal) {
      //   this.loading2 = true;
      //   Api.get(`/customers-item?status=1&customer_name=${newVal}`)
      //     .then((res) => {
      //       this.loading2 = false;
      //       this.customersItem = res.data.data;
      //     })
      //     .catch((err) => {
      //       if (err.response.status !== 401) {
      //         this.$toastr.e("Failed to load data!" + err);
      //         this.loading2 = false;
      //       }
      //     });
      // }

      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },

    // "filters.damage_claim"() {
    //   this.search();
    // },
    // "filters.claim_status"() {
    //   this.search();
    // },
    // "filters.keys"() {
    //   this.search();
    // },
    // "filters.title"() {
    //   this.search();
    // },
    // "filters.location"() {
    //   this.search();
    // },
    // "filters.status"() {
    //   this.search();
    // },
    // "filters.loading_type"() {
    //   this.search();
    // },
    // "filters.notes_status"() {
    //   this.search();
    // },
    // "filters.vehicle_type"() {
    //   this.search();
    // },
    "filters.customer_user_id"() {
      this.search();
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}

.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 12px;
  width: 200px !important;
}

.manage-details {
  color: red !important;
  background: white !important;
}

table,
table th,
table td {
  border: 1px solid darkgrey;
}

.date {
  min-width: 107px;
}

th.sortable {
  cursor: pointer;
}

th.sortable:hover {
  color: black;
  text-decoration: underline;
}

.mdi.mdi-sort-descending,
.mdi.mdi-sort-ascending {
  float: right;
}
.complain-counter {
  position: absolute;
  right: -20px;
  top: -18px;
  min-width: 19px;
  background: #555;
  border-radius: 5px;
  text-align: center;
  color: white;
  min-height: 16px;
  font-size: 10px;
  padding: 2px;
}
</style>
