<template>
  <v-row class="mt-1 mr-2">
    <v-dialog v-model="dialog" scrollable max-width="85%" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="permissions['vehicles.store'] == false"></div>
        <div v-else :class="{ bbb: dash }">
          <v-btn
            v-if="dash && custom"
            small
            color="#E0E0E0"
            elevation="0"
            class="text-capitalize w-100 mt-0"
            width="100%"
            v-bind="attrs"
            @click="(dialog = true), emptyForm(), (editing = false), makeApi(0)"
            v-on="on"
            >Add a car
          </v-btn>
          <v-btn
            v-if="newz"
            color="primary"
            @click="(dialog = true), (editing = false), makeApi(0)"
            dark
            v-bind="attrs"
            v-on="on"
            >New vehicle
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-card-title style="border-bottom: 1px solid #eeee">
          <span class="headline">{{ editing ? "Edit" : "New" }} vehicle</span>
          <v-btn class="ml-auto" color="blue darken-1" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row justify="space-between">
              <v-col cols="12" md="6">
                <v-card-title>
                  <span class="headline">Customer info</span>
                </v-card-title>

                <v-autocomplete
                  dense
                  outlined
                  v-model="form.customer_user_id"
                  name="customer_user_id"
                  :items="customersItem"
                  item-text="customer_name"
                  item-value="user_id"
                  :search-input.sync="customerUserId"
                  autocomplete="none"
                  hide-no-data
                  no-filter
                  label="CUSTOMER"
                  :error-messages="errors.customer_user_id"
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            data.item.customer_name +
                            ' (' +
                            data.item.legacy_customer_id +
                            ')'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.company_name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <v-text-field
                  v-model="legacyCustomerId"
                  name="legacyCustomerId"
                  :disabled="true"
                  label="Customer ID"
                  :error-messages="errors.legacyCustomerId"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="customarCompanyName"
                  name="customarCompanyName"
                  :disabled="true"
                  label="Customer Company Name"
                  :error-messages="errors.customer_user_id"
                  outlined
                  dense
                ></v-text-field>

                <v-card-title>
                  <span class="headline">Towing info</span>
                </v-card-title>

                <v-row>
                  <v-col cols="6" md="6">
                    Condition
                    <v-radio-group
                      v-model="form.condition"
                      name="condition"
                      row
                    >
                      <v-radio label="Operable" value="1"></v-radio>
                      <v-radio label="Non-operable" value="0"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="6" md="6">
                    Damaged
                    <v-radio-group v-model="form.damaged" name="damaged" row>
                      <v-radio label="Yes" value="1"></v-radio>
                      <v-radio label="No" value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    Picture
                    <v-radio-group v-model="form.pictures" name="pictures" row>
                      <v-radio label="Yes" value="1"></v-radio>
                      <v-radio label="No" value="0"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="6" md="6">
                    Towed
                    <v-radio-group v-model="form.towed" name="towed" row>
                      <v-radio label="Yes" value="1"></v-radio>
                      <v-radio label="No" value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" md="6">
                    Key
                    <v-radio-group v-model="form.keys" name="keys" row>
                      <v-radio label="Yes" value="1"></v-radio>
                      <v-radio label="No" value="0"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-textarea
                      outlined
                      rows="3"
                      cols="4"
                      label="Key note"
                      v-model="form.key_note"
                      name="key_note"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-card-title>
                  <span class="headline">Title info</span>
                </v-card-title>

                <v-col cols="12">
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="titlesItem"
                    v-model="form.title_type"
                    name="title_type"
                    @change="changeTitleType"
                    outlined
                    dense
                    label="Title Type"
                    :error-messages="errors.title_type"
                  ></v-select>
                  Title
                  <v-radio-group v-model="form.title" name="title" row>
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="0"></v-radio>
                  </v-radio-group>

                  <v-menu
                    ref="datePicker9"
                    v-model="datePicker9"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :error-messages="errors.title_received_date"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.title_received_date"
                        name="title_received_date"
                        label="Title Receive Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors.title_received_date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      style="height: 350px"
                      v-model="form.title_received_date"
                      name="title_received_date"
                      @input="datePicker9 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>

                  <v-text-field
                    v-model="form.title_number"
                    name="title_number"
                    label="Title No"
                    :error-messages="errors.title_number"
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="form.title_state"
                    name="title_state"
                    label="Title State"
                    :error-messages="errors.title_state"
                    outlined
                    dense
                  ></v-text-field>

                  <v-menu
                    ref="datePicker10"
                    v-model="datePicker10"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :error-messages="errors.towing_request_date"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.towing_request_date"
                        name="towing_request_date"
                        label="Towing Requst Date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors.towing_request_date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      style="height: 350px"
                      v-model="form.towing_request_date"
                      @input="datePicker10 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="datePicker11"
                    v-model="datePicker11"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :error-messages="errors.pickup_date"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.pickup_date"
                        name="pickup_date"
                        label="Pickup date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors.pickup_date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      style="height: 350px"
                      v-model="form.pickup_date"
                      @input="datePicker11 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="datePicker12"
                    v-model="datePicker12"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :error-messages="errors.deliver_date"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.deliver_date"
                        name="deliver_date"
                        label="Delivery date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors.deliver_date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      style="height: 350px"
                      v-model="form.deliver_date"
                      @input="datePicker12 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>

                  <v-menu
                    v-if="form.status === 6 || form.status === 7"
                    ref="datePicker13"
                    v-model="datePicker13"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :error-messages="errors.handed_over_date"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        outlined
                        v-model="form.handed_over_date"
                        name="handed_over_date"
                        label="Handed Over date"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="errors.handed_over_date"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      style="height: 350px"
                      v-model="form.handed_over_date"
                      @input="datePicker13 = false"
                      no-title
                      scrollable
                    ></v-date-picker>
                  </v-menu>

                  <v-textarea
                    outlined
                    rows="3"
                    cols="4"
                    label="Note"
                    v-model="form.note"
                    name="note"
                    :error-messages="errors.note"
                  ></v-textarea>
                </v-col>

                <v-row>
                  <v-col cols="12" md="12">
                    <v-card-title>
                      <span class="headline"
                        >Check options included on the vehicle
                      </span>
                    </v-card-title>
                    <v-card flat>
                      <v-card-text>
                        <v-container fluid>
                          <v-row>
                            <v-row class="py-0">
                              <v-col
                                cols="6 py-0"
                                v-for="(item, index) in vehicleCheckOptions"
                                :key="index"
                              >
                                <v-checkbox
                                  class="ma-0"
                                  v-model="form.vehicle_features[item.id]"
                                  :label="item.name"
                                  :name="item.name"
                                  color="primary"
                                  :value="item.id"
                                ></v-checkbox>
                              </v-col>
                            </v-row>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" md="6">
                <v-card-title>
                  <span class="headline">Vehicle info</span>
                </v-card-title>
                <v-select
                  item-text="title"
                  item-value="id"
                  :items="statusItem"
                  v-model="form.status"
                  name="status"
                  outlined
                  dense
                  label="Status"
                  :error-messages="errors.status"
                ></v-select>

                <v-select
                  item-text="title"
                  item-value="title"
                  :items="vehicleTypeItem"
                  v-model="form.vehicle_type"
                  name="vehicle_type"
                  outlined
                  dense
                  label="Vehicle Type"
                  :error-messages="errors.vehicle_type"
                ></v-select>

                <v-row class="py-2">
                  <v-text-field
                    style="margin-left: 13px"
                    v-model="form.vin"
                    name="vin"
                    label="Vin"
                    :error-messages="errors.vin"
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn
                    style="margin: 0px 7px"
                    :disabled="loading"
                    :loading="autofillloading"
                    @click="autoFillField"
                    >auto fill
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-row>

                <v-text-field
                  v-model="form.year"
                  name="year"
                  label="Year"
                  :error-messages="errors.year"
                  outlined
                  dense
                ></v-text-field>

                <v-autocomplete
                  dense
                  outlined
                  v-model="form.color"
                  name="color"
                  :items="colorsItem"
                  item-text="color"
                  item-value="color"
                  :search-input.sync="colorId"
                  hide-no-data
                  hide-selected
                  label="Color"
                  autocomplete="none"
                  :error-messages="errors.color"
                ></v-autocomplete>

                <v-text-field
                  v-model="form.model"
                  name="model"
                  label="Model"
                  :error-messages="errors.model"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.make"
                  name="make"
                  label="Make"
                  :error-messages="errors.make"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.hat_number"
                  name="hat_number"
                  label="Hat"
                  :error-messages="errors.hat_number"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.weight"
                  name="weight"
                  type="text"
                  label="Weight (LB)"
                  @keyup="weightToKg"
                  :error-messages="errors.weight"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.weight_in_kg"
                  name="weight_in_kg"
                  label="Weight (KG)"
                  type="text"
                  @keyup="weightToLb"
                  :error-messages="errors.weight_in_kg"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.value"
                  name="value"
                  label="Value"
                  :error-messages="errors.value"
                  outlined
                  dense
                  @keypress="(e) => onlyNumberCheck(e)"
                ></v-text-field>

                <v-text-field
                  v-model="form.license_number"
                  name="license_number"
                  label="Buyer No"
                  :error-messages="errors.license_number"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.towed_from"
                  name="towed_from"
                  label="Towed form"
                  :error-messages="errors.towed_from"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.lot_number"
                  name="lot_number"
                  label="Lot No"
                  :error-messages="errors.lot_number"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.towed_amount"
                  name="towed_amount"
                  label="Towed amount"
                  :error-messages="errors.towed_amount"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.storage_amount"
                  name="storage_amount"
                  label="Storage amount"
                  :error-messages="errors.storage_amount"
                  outlined
                  dense
                  @keypress="(e) => onlyNumberCheck(e)"
                ></v-text-field>

                <v-text-field
                  v-model="form.title_amount"
                  name="title_amount"
                  label="Title amount"
                  :error-messages="errors.title_amount"
                  outlined
                  dense
                ></v-text-field>

                <v-text-field
                  v-model="form.check_number"
                  name="check_number"
                  label="Check No"
                  :error-messages="errors.check_number"
                  outlined
                  dense
                  @keypress="(e) => onlyNumberCheck(e)"
                ></v-text-field>

                <v-text-field
                  v-model="form.additional_charges"
                  name="additional_charges"
                  label="Add CHGS"
                  :error-messages="errors.additional_charges"
                  outlined
                  dense
                  @keypress="(e) => onlyNumberCheck(e)"
                ></v-text-field>



                <v-select
                  item-text="title"
                  item-value="id"
                  :items="auctionsItem"
                  v-model="form.auction_at"
                  name="auction_at"
                  outlined
                  dense
                  label="Auctions At"
                  :error-messages="errors.auction_at"
                ></v-select>

                <div class="custom_class">
                  <div class="">
                    <b>Hybrid</b>
                  </div>
                  <v-radio-group v-model="form.hybrid" name="hybrid" row>
                    <v-radio label="Yes" value="1"></v-radio>
                    <v-radio label="No" value="2"></v-radio>
                  </v-radio-group>
                </div>

                <v-autocomplete
                  dense
                  outlined
                  v-model="form.location_id"
                  name="location_id"
                  :items="locationsItem"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="locationId"
                  hide-no-data
                  hide-selected
                  label="Location"
                  autocomplete="none"
                  :error-messages="errors.location_id"
                ></v-autocomplete>

                <v-select
                    v-if="form.location_id"
                    item-text="title"
                    item-value="id"
                    :items="yardsItem"
                    v-model="form.yard_id"
                    name="yard_id"
                    outlined
                    dense
                    label="Yard Name"
                    :error-messages="errors.yard_id"
                ></v-select>

              </v-col>

              <v-col cols="12" md="12">
                <v-card-title>
                  <span class="headline">Condition of vehicle </span>
                </v-card-title>

                <v-row class="ml-1" v-if="conditionOptions.length > 0">
                  <v-col
                    md="2"
                    class="py-0"
                    v-for="(item, index) in conditionOptions"
                    :key="index"
                  >
                    <v-text-field
                      v-model="form.vehicle_conditions[item.id]"
                      :label="item.name"
                      :name="item.name"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <br />
            <br />
            <v-row justify="space-between">
              <v-row>
                <v-col cols="3" md="3"> Vehicle Auction Photos </v-col>
                <v-col cols="9" md="9">
                  <vue-dropzone
                    ref="vehicleAuctionPhotos"
                    id="vehicleAuctionPhotos"
                    @vdropzone-removed-file="removeAuctionPhoto"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="storeAuctionSuccess"
                    @vdropzone-error="storeError"
                    :options="vehicleAuctionPhotoOptions"
                  ></vue-dropzone>
                  <button @click="removeAllAuctionPhotoFiles">
                    Remove All Files
                  </button>
                </v-col>
              </v-row>
            </v-row>

            <br />
            <br />
            <v-row justify="space-between">
              <v-row>
                <v-col cols="3" md="3"> Vehicle Pickup Photos </v-col>
                <v-col cols="9" md="9">
                  <vue-dropzone
                    ref="vehiclePickupPhotos"
                    id="vehiclePickupPhotos"
                    @vdropzone-removed-file="removePickupPhoto"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="storePickupSuccess"
                    @vdropzone-error="storeError"
                    :options="vehiclePickupPhotoOptions"
                  ></vue-dropzone>
                  <button @click="removeAllPickupPhotoFiles">
                    Remove All Files
                  </button>
                </v-col>
              </v-row>
            </v-row>

            <br />
            <br />
            <v-row justify="space-between">
              <v-row>
                <v-col cols="3" md="3"> USA YARD PHOTOS </v-col>
                <v-col cols="9" md="9">
                  <vue-dropzone
                    ref="vehicleImages"
                    id="vehicleImages"
                    @vdropzone-removed-file="removeVehicleImage"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="storeVehicleImageSuccess"
                    @vdropzone-error="storeError"
                    :options="vehicleImageOptions"
                  ></vue-dropzone>
                  <button @click="removeAllImageFiles">Remove All Files</button>
                </v-col>
              </v-row>
            </v-row>
            <br />
            <br />
            <v-row justify="space-between">
              <v-row>
                <v-col cols="3" md="3"> Vehicle Arrived Photos </v-col>
                <v-col cols="9" md="9">
                  <vue-dropzone
                    ref="vehicleArrivedPhotos"
                    id="vehicleArrivedPhotos"
                    @vdropzone-removed-file="removeArrivedPhoto"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="storeArrivedSuccess"
                    @vdropzone-error="storeError"
                    :options="vehicleArrivedPhotoOptions"
                  ></vue-dropzone>
                  <button @click="removeAllArrivedPhotoFiles">
                    Remove All Files
                  </button>
                  {{ cureent }}
                </v-col>
              </v-row>
            </v-row>
            <br />
            <br />
            <v-row justify="space-between">
              <v-row>
                <v-col cols="3" md="3"> Vehicle Document </v-col>
                <v-col cols="9" md="9">
                  <vue-dropzone
                    ref="vehicleDocuments"
                    id="vehicleDocuments"
                    @vdropzone-removed-file="removeVehicleDocument"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="storeDocumentSuccess"
                    @vdropzone-error="storeError"
                    :options="vehicleDocumentOptions"
                  ></vue-dropzone>
                  <button @click="removeAllDocumentFiles">
                    Remove All Files
                  </button>
                </v-col>
              </v-row>
            </v-row>
            <br />
            <br />
            <v-row justify="space-between">
              <v-row>
                <v-col cols="3" md="3"> Vehicle Invoice </v-col>
                <v-col cols="9" md="9">
                  <vue-dropzone
                    ref="vehicleInvoice"
                    id="vehicleInvoice"
                    @vdropzone-removed-file="removeVehicleInvoice"
                    @vdropzone-sending="sendingEvent"
                    @vdropzone-success="storeInvoiceSuccess"
                    @vdropzone-error="storeError"
                    :options="vehicleInvoiceOptions"
                  ></vue-dropzone>
                  <button @click="removeAllInvoiceFiles()">
                    Remove All Files
                  </button>
                </v-col>
              </v-row>
            </v-row>
            <br />
            <br />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            class="primary"
            :disabled="loading"
            @click="editing ? update() : store()"
          >
            {{ editing ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Api from "@/services/Api";
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

const _token = "Bearer " + localStorage.getItem("authtoken");

export default {
  props: ["show", "custom", "dash", "newz"],
  name: "Manage",
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    let cureent;
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      permissions: JSON.parse(localStorage.getItem("permissions")),
      AuthStr: "Bearer " + localStorage.getItem("authtoken"),
      defaultDate: "2018",
      DatePickerFormat: "yyyy",
      date: "",
      menu: false,
      modal: false,
      menu2: false,
      id: null,
      editing: false,
      errors: {},
      dialog: this.show,
      loading: false,
      autofillloading: false,
      loading_types: [
        { title: "Select one", id: "" },
        { title: "Full", id: 1 },
        { title: "Mix", id: 2 },
      ],
      form: {
        fileDocumentUrls: [],
        fileUrls: {
          photos: [],
          document_files: [],
          invoice_photos: [],
          auction_photos: [],
          pickup_photos: [],
          arrived_photos: [],
        },
        // fileUrls:[],
        auction_photos: [],
        pickup_photos: [],
        arrived_photos: [],
        invoice_photos: [],
        vehicle_documents: [],
        checkboxItem: [],
        conditionItem: [],
        customer_user_id: null,
        company_name: "",
        location_id: "",
        yard_id: "",
        customer_name: "",
        condition: "0",
        damaged: "1",
        pictures: "0",
        towed: "0",
        keys: "0",
        title: "0",
        vehicle_features: [],
        vehicle_conditions: [],
        hybrid: "2"
      },

      merged: {},

      statusItem: [
        { title: "Select Status", id: "" },
        { title: "ON HAND", id: 1 },
        { title: "MANIFEST", id: 2 },
        { title: "ON THE WAY", id: 3 },
        { title: "SHIPPED", id: 4 },
        { title: "PICKED UP", id: 5 },
        { title: "ARRIVED", id: 6 },
      ],

      vehicleTypeItem: [
        { title: "Select One", id: "" },
        { title: "SUV", id: "SUV" },
        { title: "Sedan", id: "Sedan" },
        { title: "Van", id: "Van" },
        { title: "Pickup", id: "Pickup" },
        { title: "Truck", id: "Truck" },
        { title: "Mortorcycle", id: "Mortorcycle" },
      ],
      titleTypeItem: [
        { title: "Select Type", id: "" },
        { title: "Type one", id: 1 },
        { title: "Type Two", id: 2 },
      ],
      titlesItem: [
        { title: "Select Title", id: "" },
        { title: "NO TITLE", id: 0 },
        { title: "EXPORTABLE", id: 1 },
        { title: "PENDING", id: 2 },
        { title: "BOS", id: 3 },
        { title: "LIEN", id: 4 },
        { title: "MV907", id: 5 },
        { title: "REJECTED", id: 6 },
      ],

      locationsItem: [],
      locationIdLoading: false,
      locationId: "",
      datePicker9: "",
      datePicker10: "",
      datePicker11: "",
      datePicker12: "",
      datePicker13: "",
      auctionsItem: [
        { title: "Select Auction", id: "" },
        { title: "Copart", id: "1" },
        { title: "IAA", id: "2" },
      ],
      yardsItem: [],
      customersItem: [],
      colorsItem: [],
      colorId: "",
      colorIdLoading: false,
      customarCompanyName: "",
      legacyCustomerId: "",
      vehicleCheckOptions: [],
      customerUserId: "",
      checkboxItem: [],
      conditionOptions: [],
      conditionItem: [],
      cureent: cureent,
      vehicleImageOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },
      vehicleDocumentOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        headers: { Authorization: _token },
      },
      vehicleInvoiceOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        headers: { Authorization: _token },
      },
      vehicleAuctionPhotoOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },
      vehiclePickupPhotoOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },
      vehicleArrivedPhotoOptions: {
        url: null,
        thumbnailWidth: 150,
        maxFilesize: 10,
        autoProcessQueue: true,
        addRemoveLinks: true,
        acceptedFiles: ".jpg,.jpeg,.png",
        headers: { Authorization: _token },
      },
      loader: null,
    };
  },
  methods: {
    onlyNumberCheck(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeAllImageFiles() {
      this.form.photos = [];
      this.form.fileUrls.photos = [];
      if (this.$refs.vehicleImages) {
        this.$refs.vehicleImages.removeAllFiles();
      }
      this.form.pictures = "0";
    },
    removeAllDocumentFiles() {
      this.form.fileUrls.fileDocumentUrls = [];
      this.form.fileDocumentUrls = [];
      if (this.$refs.vehicleDocuments) {
        this.$refs.vehicleDocuments.removeAllFiles();
      }
    },
    removeAllInvoiceFiles() {
      this.form.fileUrls.invoice_photos = [];
      this.form.invoice_photos = [];
      if (this.$refs.vehicleInvoice) {
        this.$refs.vehicleInvoice.removeAllFiles();
      }
    },
    removeAllAuctionPhotoFiles() {
      this.form.auction_photos = [];
      this.form.fileUrls.auction_photos = [];
      if (this.$refs.vehicleAuctionPhotos) {
        this.$refs.vehicleAuctionPhotos.removeAllFiles();
      }
    },
    removeAllPickupPhotoFiles() {
      this.form.pickup_photos = [];
      this.form.fileUrls.pickup_photos = [];
      if (this.$refs.vehiclePickupPhotos) {
        this.$refs.vehiclePickupPhotos.removeAllFiles();
      }
    },
    removeAllArrivedPhotoFiles() {
      this.form.arrived_photos = [];
      this.form.fileUrls.arrived_photos = [];
      if (this.$refs.vehicleArrivedPhotos) {
        this.$refs.vehicleArrivedPhotos.removeAllFiles();
      }
    },

    changeTitleType() {
      if (this.form.title_type === 1) {
        this.form.title = "1";
      } else {
        this.form.title = "0";
      }
    },
    sendingEvent(file, xhr, formData) {
      this.loading = true;
    },

    storeSuccess(type) {
      this.loading = false;
      if (!this.form.fileUrls.hasOwnProperty(type)) {
        this.form.fileUrls[type] = [];
      }
    },

    storeVehicleImageSuccess(file, response) {
      this.loading = false;
      if (this.form.photos) {
        this.form.photos = [];
      }
      this.form.photos.push(response.data);
      this.form.fileUrls.photos.push(response.data);
      this.form.pictures = "1";
      console.log(this.form.fileUrls);
    },

    storeDocumentSuccess(file, response) {
      this.loading = false;
      if (this.form.fileDocumentUrls) {
        this.form.fileDocumentUrls = [];
      }
      this.form.fileUrls.document_files.push(response.data);
    },
    storeInvoiceSuccess(file, response) {
      this.loading = false;
      this.form.fileUrls.invoice_photos.push(response.data);
    },
    storeAuctionSuccess(file, response) {
      this.loading = false;
      this.form.fileUrls.auction_photos.push(response.data);
    },
    storePickupSuccess(file, response) {
      this.loading = false;
      this.form.fileUrls.pickup_photos.push(response.data);
    },
    storeArrivedSuccess(file, response) {
      this.loading = false;
      this.form.fileUrls.arrived_photos.push(response.data);
    },

    storeError(file, message, xhr) {
      this.loading = false;
      this.dialog = true;
      if (xhr.status === 422) {
        // this.errors = message.errors;
      } else {
        this.dialog = false;
        this.loading = false;
      }

      this.$toastr.e("Failed to create data! " + message.message);
    },

    emptyForm() {
      this.customarCompanyName = "";
      this.legacyCustomerId = "";
      this.form = {
        vehicle_id: "",
        email: "",
        vehicle_name: "",
        company_name: "",
        customer_name: "",
        phone_usa: "",
        phone_uae: "",
        trn_usa: "",
        trn_uae: "",
        status: null,
        fax: "",
        address_line_1: "",
        address_line_2: "",
        country: "",
        pickup_date: "",
        handed_over_date: "",
        city: "",
        state: "",
        zip_code: "",
        other_emails: "",
        note: "",
        loading_type: "",
        conditionItem: [],
        documents: "",
        fileDocumentUrls: [],
        auction_photos: [],
        pickup_photos: [],
        arrived_photos: [],
        invoice_photos: [],
        vehicle_documents: [],
        customer_user_id: null,
        to_condition: "",
        photos: [],
        make: "",
        year: "",
        model: "",
        weight: "",
        weight_in_kg: "",
        title_received_date: "",
        vehicle_features: [],
        vehicle_conditions: [],
        condition: "0",
        damaged: "1",
        pictures: "0",
        towed: "0",
        keys: "0",
        title: "0",
        fileUrls: {
          photos: [],
          document_files: [],
          invoice_photos: [],
          auction_photos: [],
          pickup_photos: [],
          arrived_photos: [],
        },
      };

      this.removeAllImageFiles();
      this.removeAllDocumentFiles();
      this.removeAllAuctionPhotoFiles();
      this.removeAllPickupPhotoFiles();
      this.removeAllArrivedPhotoFiles();

      this.conditionOptions.forEach((value) => {
        if (!this.form.vehicle_conditions.hasOwnProperty(value.id)) {
          this.form.vehicle_conditions[value.id] = "";
        }
      });
    },

    store() {
      this.loading = true;
      this.errors = {};
      this.form.checkboxItem = this.checkboxItem;
      this.form.conditionItem = this.conditionItem;

      const removeArr = [
        "eta",
        "export_date",
        "booking_number",
        "loading_date",
        "manifest_date",
        "seal_number",
        "ar_number",
        "container_number",
        "destination",
        "customer_name",
        "company_name",
        "state_name",
        "visible_export_button",
        "visible_claim_button",
        "visible_key_missing_button",
        "visible_storage_button",
        "white_check_mark",
        "eyes",
        "raised_hands",
        "note_status",
        "photo",
        "photos",
        "auction_photos",
        "arrived_photos",
        "invoice_photos",
        "pickup_photos",
        "vehicle_documents",
      ];
      const formObject = this.form;

      removeArr.forEach((e) => {
        delete formObject[e];
      });

      console.log(formObject);

      Api.post(`/vehicles`, formObject)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.removeAllImageFiles();
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.$emit("cancelForm");
          this.$router.push("/vehicles");
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
          }
          this.$toastr.e(
            "Failed to create data! One or more required field missing. "
          );
        });

      this.editing = false;
    },
    routerChane() {
      if (this.dash) {
        this.$router.push("/vehicles");
      }
    },
    // getCustomerItem(e) {},

    async update() {
      this.loading = true;
      this.errors = {};

      const removeArr = [
        "eta",
        "export_date",
        "booking_number",
        "loading_date",
        "manifest_date",
        "seal_number",
        "ar_number",
        "container_number",
        "destination",
        "customer_name",
        "company_name",
        "state_name",
        "visible_export_button",
        "visible_claim_button",
        "visible_key_missing_button",
        "visible_storage_button",
        "white_check_mark",
        "eyes",
        "raised_hands",
        "note_status",
        "photo",
        "photos",
        "auction_photos",
        "arrived_photos",
        "invoice_photos",
        "pickup_photos",
        "vehicle_documents",
      ];
      const formObject = this.form;

      removeArr.forEach((e) => {
        delete formObject[e];
      });

      await Api.put(`/vehicles/${this.id}`, formObject)
        .then((res) => {
          this.dialog = false;
          this.loading = false;
          this.emptyForm();
          this.$toastr.s(res.data.message);
          this.$emit("dataUpdated");
          this.$emit("cancelForm");
          this.editing = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
            this.$toastr.e(
              "Failed to update data! One or more required field missing."
            );
          } else {
            this.$toastr.e(
              err.response?.data?.message ??
                "Failed to update data! One or more required field missing."
            );
          }
          this.loading = false;
        });
    },

    edit(id) {
      this.dialog = true;
      this.editing = true;

      this.emptyForm();

      this.removeAllImageFiles();
      this.removeAllDocumentFiles();
      this.removeAllInvoiceFiles();
      this.removeAllAuctionPhotoFiles();
      this.removeAllPickupPhotoFiles();
      this.removeAllArrivedPhotoFiles();

      if (!id) {
        return;
      }

      this.makeApi(id);
      this.id = id;
      this.loading = true;

      this.errors = {};
      Api.get(`/vehicles/${id}`)
        .then((res) => {
          this.form = res.data.data;
          this.form.invoice_photos = res.data.data.invoice_photos;
          // if (!res.data.data.fileUrls) {
          //     this.form.fileUrls = {};
          // }
          if (!res.data.data.fileDocumentUrls) {
            this.form.fileDocumentUrls = [];
          }

          if (!res.data.data.checkboxItem) {
            this.form.checkboxItem = [];
          }
          if (!res.data.data.conditionItem) {
            this.form.conditionItem = [];
          }

          this.form.fileUrls = {
            photos: this.form.photos,
            document_files: this.form.vehicle_documents,
            invoice_photos: this.form.invoice_photos,
            auction_photos: this.form.auction_photos,
            pickup_photos: this.form.pickup_photos,
            arrived_photos: this.form.arrived_photos,
          };

          this.customerName(res.data.data.customer_user_id);

          this.form.photos.map((value) => {
            let file = { size: value.size, name: value.name, type: value.type };
            this.$refs.vehicleImages.manuallyAddFile(file, value.url);
          });

          this.form.arrived_photos.map((value) => {
            let file = { size: value.size, name: value.name, type: value.type };
            this.$refs.vehicleArrivedPhotos.manuallyAddFile(file, value.url);
          });

          this.form.vehicle_documents.map((value) => {
            let file = { size: value.size, url: value.url, type: value.type };
            var ext = file.url.split(".").pop();
            let url = file.url;
            if (ext === "pdf") {
              url = Api.defaults.imageURL + "/images/pdf_thumbnail.jpg";
            }

            this.$refs.vehicleDocuments.manuallyAddFile(file, url, null, null, {
              dontSubstractMaxFiles: false,
              addToFiles: true,
            });
          });

          this.form.pickup_photos.map((value) => {
            let file = { size: value.size, name: value.name, type: value.type };
            this.$refs.vehiclePickupPhotos.manuallyAddFile(file, value.url);
          });

          this.form.auction_photos.map((value) => {
            let file = {
              size: value.size,
              name: "1618395998.jpg",
              type: value.type,
            };
            this.$refs.vehicleAuctionPhotos.manuallyAddFile(file, value.url);
          });

          this.form.invoice_photos.map((value) => {
            let file = { size: value.size, url: value.url, type: value.type };
            var ext = file.url.split(".").pop();
            let url = file.url;
            if (ext == "pdf") {
              url = Api.defaults.imageURL + "/images/pdf_thumbnail.jpg";
            }
            this.$refs.vehicleInvoice.manuallyAddFile(file, url, null, null, {
              dontSubstractMaxFiles: false,
              addToFiles: true,
            });
          });

          this.customarCompanyName = this.form.company_name;
          this.legacyCustomerId = this.form.legacy_customer_id;

          this.dialog = true;
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data! " + err);
          }
        });
    },
    makeApi(id) {
      this.vehicleImageOptions.url = `${Api.defaults.baseURL}/vehicles/${id}/photos-upload?type=1`;
      this.vehicleDocumentOptions.url = `${Api.defaults.baseURL}/vehicles/${id}/documents-upload`;
      this.vehicleAuctionPhotoOptions.url = `${Api.defaults.baseURL}/vehicles/${id}/photos-upload?type=2`;
      // this.vehicleAuctionPhotoOptions.headers = { "Authorization": "Bearer "+ this.token };
      this.vehiclePickupPhotoOptions.url = `${Api.defaults.baseURL}/vehicles/${id}/photos-upload?type=3`;
      this.vehicleArrivedPhotoOptions.url = `${Api.defaults.baseURL}/vehicles/${id}/photos-upload?type=4`;
      this.vehicleInvoiceOptions.url = `${Api.defaults.baseURL}/vehicles/${id}/documents-upload?type=2`;
    },
    cancel() {
      this.editing = false;
      this.dialog = false;
      this.emptyForm();
      this.errors = {};
      this.$emit("cancelForm");
      this.removeAllImageFiles();
    },

    changeCustomer() {
      for (const i of this.customersItem) {
        if (i.user_id === this.form.customer_user_id) {
          this.customarCompanyName = i.company_name;
          this.legacyCustomerId = i.legacy_customer_id;
        }
      }
    },

    autoFillField() {
      if (this.form.vin) {
        this.loading = true;
        this.autofillloading = true;
        this.loader = "loading";
        var url =
          "https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvaluesextended/" +
          this.form.vin +
          "?format=json";
        axios
          .get(url)
          .then((res) => {
            this.form.make = res.data.Results[0].Make.toUpperCase();
            this.form.model = res.data.Results[0].Model.toUpperCase();
            this.form.year = res.data.Results[0].ModelYear;

            this.getVehicleWeight(
              this.form.make,
              this.form.model,
              this.form.year
            );

            this.dialog = true;
            this.loading = false;
            this.autofillloading = false;
            this.loader = null;
          })
          .catch((err) => {
            if (err.response.status !== 422) {
              this.$toastr.e("Failed to Filed auto fill! " + err);
            }
            this.loading = false;
            this.autofillloading = false;
            this.loader = null;
          });
      } else {
        this.$toastr.e("please fill vin field first");
      }
    },

    getVehicleWeight(make, model, year) {
      Api.get(`/vehicle-weight?make=${make}&model=${model}&year=${year}`)
        .then((res) => {
          this.form.weight = res.data.weight;
          this.colorIdLoading = false;
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errors = err.response.data.errors;
          } else {
            this.dialog = false;
            this.loading = false;
          }
          if (err.response.status !== 401) {
            this.colorIdLoading = false;
            this.$toastr.e("Failed to create data! ");
          }
        });
    },

    customerName(id) {
      Api.get(`/customers-item?user_id=${id}`)
        .then((res) => {
          this.customersItem = res.data.data;
          this.changeCustomer();
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
    },
    removeVehicleDocument(file) {
      let index = this.form.vehicle_documents
        ? this.form.vehicle_documents.findIndex((x) => x.name === file.name)
        : -1;
      this.form.vehicle_documents.splice(index, 1);
    },
    removeVehicleImage(file) {
      let index = this.form.fileUrls.photos
        ? this.form.fileUrls.photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.fileUrls.photos.splice(index, 1);

      if (this.form.fileUrls.photos.length == 0) {
        this.form.pictures = "0";
      }
    },
    removeVehicleInvoice(file) {
      let index = this.form.invoice_photos
        ? this.form.invoice_photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.invoice_photos.splice(index, 1);
    },
    removeAuctionPhoto(file) {
      let index = this.form.auction_photos
        ? this.form.auction_photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.auction_photos.splice(index, 1);
    },
    removePickupPhoto(file) {
      let index = this.form.pickup_photos
        ? this.form.pickup_photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.pickup_photos.splice(index, 1);
    },
    removeArrivedPhoto(file) {
      let index = this.form.arrived_photos
        ? this.form.arrived_photos.findIndex((x) => x.name === file.name)
        : -1;
      this.form.arrived_photos.splice(index, 1);
    },
    cusotmarAutoCompleteHandelar(newVal) {
      Api.get(`/customers-item?customer_name=${newVal}`)
        .then((res) => {
          this.customersItem = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          }
        });
    },
    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },

    getYards(location_id) {
      Api.get(`/settings/yards?location_id=${location_id}&status=1`)
          .then((res) => {
            this.yardsItem = res.data.data.map((item) => {
              return {
                title : item.name,
                id : item.id,
              }
            });
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.$toastr.e("Failed to load data!" + err);
            }
          });
    },

    weightToLb() {
      if(this.form.weight_in_kg) {
        let convertedLb = parseInt(this.form.weight_in_kg) * 2.20462;
        this.form.weight = Math.round(convertedLb);
      }else {
        this.form.weight = '';
      }
    },

    weightToKg() {
      if(this.form.weight) {
        let convertedKg = parseInt(this.form.weight) / 2.20462;
        this.form.weight_in_kg = Math.round(convertedKg);
      }else {
        this.form.weight_in_kg = '';
      }
    }

  },
  created() {
    this.emptyForm();
    if (this.editing) {
      this.edit(this.id);
    }
  },
  watch: {
    "form.customer_user_id": function () {
      this.changeCustomer();
    },
    customerUserId: function (newVal) {
      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },

    "form.location_id" : function (newVal) {
      if (!newVal) {
        return;
      }

      this.getYards(newVal);
    },

    // form(newVal) {
    //   this.merged = Object.assign({}, newVal, this.form.fileUrls);
    // },

    // fileUrls(newVal) {
    //   this.merged = Object.assign({}, newVal, this.form);
    // },
  },
  mounted() {
    this.customerName("");

    this.locationsItem = this.$store.state.auth.locations;
    this.colorsItem = this.$store.state.auth.vehicle_colors;
    this.vehicleCheckOptions = this.$store.state.auth.vehicle_checkbox_items;
    this.conditionOptions = this.$store.state.auth.vehicle_condition_items;

    if (this.roleId == 8) {
      this.statusItem = [
        { title: "Select Status", id: "" },
        { title: "ON HAND", id: 1 },
        { title: "ON THE WAY", id: 3 },
      ];
    }
  },
};
</script>
<style>
.bbb {
  width: 100%;
  margin-right: 25px;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom_class {
  display: flex;
  gap: 28px;
  align-items: center;
}
</style>
