<template>
  <div>
    <div
      v-if="permissions['exports.index'] == false"
      style="height: 50vh"
      class="d-flex align-center justify-center"
    >
      <h1 class="display-2 text-center">
        You are not allowed to view this page
      </h1>
    </div>
    <div v-else class="home">
      <v-container fluid class="bg-white mt-65">
        <div class="d-flex pb-4">
          <h1 class="primetime">Exports</h1>
          <div class="ml-auto mr-4" cols="12" md="2">
            <v-btn
              class="ml-auto mr-5"
              :loading="downloading"
              @click="exportContainer"
              color="primary"
              >Export Excel
            </v-btn>
            <manage
              class="d-inline-block"
              ref="manage"
              @dataUpdated="getData()"
              :id="editId"
              :show="formShow"
              @cancelForm="formShow = false"
            />
          </div>
        </div>
        <v-row>
          <v-col cols="12" md="10" class="pb-0 pt-0">
            <div>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    outlined
                    dense
                    v-model="filters.export_global_search"
                    label="Global Search"
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-btn :loading="filtering" color="primary" @click="search">
                    Search
                  </v-btn>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <h5>
                    Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
                    {{ this.showingTo ? this.showingTo : "0" }} of
                    {{ this.count ? this.count : "0" }}
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <details-modal
            :show="updateDetals"
            @clearform="updateDetals = !updateDetals"
            :details="details"
          />
          <v-col cols="12" md="2"></v-col>
        </v-row>
        <confirmation
          :confirming="deletingId"
          @confirmed="remove"
          :show="deleteConfirmationShow"
          @canceled="deleteConfirmationShow = false"
        />
        <vue-content-loading :width="300" :height="100" v-if="loading">
          <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
          <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
        </vue-content-loading>
        <v-simple-table class="mt-10" v-if="exports.data && !loading">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Img</th>
                <th class="text-left">Total Photos</th>
                <th class="text-left">Total Vehicles</th>
                <th
                  v-if="
                    currentSortDir == 'asc' && currentSort == 'loading_date'
                  "
                  @click="Sort('loading_date')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  <div>Loading Date</div>
                  <div>
                    <v-icon>mdi-sort-ascending</v-icon>
                  </div>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' && currentSort == 'loading_date'
                  "
                  @click="Sort('loading_date')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Loading Date
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  @click="Sort('loading_date')"
                  class="text-left date sortable"
                  style="cursor: pointer"
                >
                  Loading Date
                </th>

                <th
                  v-if="currentSortDir == 'asc' && currentSort == 'export_date'"
                  class="text-left date sortable"
                  @click="Sort('export_date')"
                  style="cursor: pointer"
                >
                  Export Date
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' && currentSort == 'export_date'
                  "
                  class="text-left date sortable"
                  @click="Sort('export_date')"
                  style="cursor: pointer"
                >
                  Export Date
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left date sortable"
                  @click="Sort('export_date')"
                  style="cursor: pointer"
                >
                  Export Date
                </th>
                <th
                  v-if="currentSortDir == 'asc' && currentSort == 'eta'"
                  class="text-left date sortable"
                  @click="Sort('eta')"
                  style="cursor: pointer"
                >
                  Eta
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="currentSortDir == 'desc' && currentSort == 'eta'"
                  class="text-left date sortable"
                  @click="Sort('eta')"
                  style="cursor: pointer"
                >
                  Eta
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left date sortable"
                  @click="Sort('eta')"
                  style="cursor: pointer"
                >
                  Eta
                </th>
                <th
                  v-if="
                    currentSortDir == 'asc' && currentSort == 'booking_number'
                  "
                  class="text-left sortable"
                  @click="Sort('booking_number')"
                  style="cursor: pointer"
                >
                  Booking No
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' && currentSort == 'booking_number'
                  "
                  class="text-left sortable"
                  @click="Sort('booking_number')"
                  style="cursor: pointer"
                >
                  Booking No
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left sortable"
                  @click="Sort('booking_number')"
                  style="cursor: pointer"
                >
                  Booking No
                </th>
                <th
                  v-if="
                    currentSortDir == 'asc' && currentSort == 'container_number'
                  "
                  class="text-left sortable"
                  @click="Sort('container_number')"
                  style="cursor: pointer"
                >
                  Container No
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' &&
                    currentSort == 'container_number'
                  "
                  class="text-left sortable"
                  @click="Sort('container_number')"
                  style="cursor: pointer"
                >
                  Container No
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left sortable"
                  @click="Sort('container_number')"
                  style="cursor: pointer"
                >
                  Container No
                </th>
                <th
                  v-if="
                    currentSortDir == 'asc' && currentSort == 'container_type'
                  "
                  class="text-left sortable"
                  @click="Sort('container_type')"
                  style="min-width: 160px; cursor: pointer"
                >
                  Container Type
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' && currentSort == 'container_type'
                  "
                  class="text-left sortable"
                  @click="Sort('container_type')"
                  style="min-width: 160px; cursor: pointer"
                >
                  Container Type
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left sortable"
                  @click="Sort('container_type')"
                  style="min-width: 160px; cursor: pointer"
                >
                  Container Type
                </th>
                <th
                  v-if="
                    currentSortDir == 'asc' && currentSort == 'port_of_loading'
                  "
                  class="text-left sortable"
                  @click="Sort('port_of_loading')"
                  style="min-width: 150px; cursor: pointer"
                >
                  Port of Loading
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' && currentSort == 'port_of_loading'
                  "
                  class="text-left sortable"
                  @click="Sort('port_of_loading')"
                  style="min-width: 150px; cursor: pointer"
                >
                  Port of Loading
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left sortable"
                  @click="Sort('port_of_loading')"
                  style="min-width: 150px; cursor: pointer"
                >
                  Port of Loading
                </th>
                <th
                  v-if="
                    currentSortDir == 'asc' &&
                    currentSort == 'port_of_discharge'
                  "
                  class="text-left sortable"
                  @click="Sort('port_of_discharge')"
                  style="min-width: 150px; cursor: pointer"
                >
                  Port of Discharge
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' &&
                    currentSort == 'port_of_discharge'
                  "
                  class="text-left sortable"
                  @click="Sort('port_of_discharge')"
                  style="min-width: 150px; cursor: pointer"
                >
                  Port of Discharge
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left sortable"
                  @click="Sort('port_of_discharge')"
                  style="min-width: 150px; cursor: pointer"
                >
                  Port of Discharge
                </th>
                <th v-if="yardShow" class="text-left" style="min-width: 100px;">

                  Yard
                </th>
                <th
                  v-if="currentSortDir == 'asc' && currentSort == 'ar_number'"
                  class="text-left sortable"
                  @click="Sort('ar_number')"
                  style="cursor: pointer"
                >
                  AR No
                  <v-icon>mdi-sort-ascending</v-icon>
                </th>
                <th
                  v-else-if="
                    currentSortDir == 'desc' && currentSort == 'ar_number'
                  "
                  class="text-left sortable"
                  @click="Sort('ar_number')"
                  style="cursor: pointer"
                >
                  AR No
                  <v-icon>mdi-sort-descending</v-icon>
                </th>
                <th
                  v-else
                  class="text-left sortable"
                  @click="Sort('ar_number')"
                  style="cursor: pointer"
                >
                  AR No
                </th>
                <th class="text-left">Status</th>
                <th class="text-left date">Manifest Date</th>
                <th class="text-left" style="min-width: 200px">Customer</th>
                <th class="text-left">Terminal</th>
                <th class="text-left" style="min-width: 150px">Vessel</th>
                <th class="text-left">BOL</th>
                <th class="text-left">Download Bl</th>
                <th class="text-left">Manifest</th>
                <th class="text-left" style="min-width: 228px">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <v-text-field
                    v-model="filters.loading_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.export_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.eta"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.booking_number"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.container_number"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    :items="containerData"
                    item-text="title"
                    item-value="id"
                    v-model="filters.container_type"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-select
                    :items="portLoadingData"
                    item-text="title"
                    item-value="id"
                    v-model="filters.port_of_loading"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-select
                    :items="portDischargeData"
                    item-text="title"
                    item-value="id"
                    v-model="filters.port_of_discharge"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td v-if="yardShow">
                    <v-select
                        item-text="name"
                        item-value="id"
                        :items="yardsItem"
                        v-model="filters.yard_id"
                        @change="manualSearch"
                        outlined
                        dense
                        hide-details
                    ></v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.ar_number"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="statuses"
                    v-model="filters.status"
                    @change="manualSearch"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.manifest_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <!--<v-text-field-->
                  <!--v-model="filters.customer"-->
                  <!--outlined-->
                  <!--dense-->
                  <!--hide-details-->
                  <!--@keypress.enter="manualSearch"-->
                  <!--&gt;</v-text-field>-->
                  <!--<v-autocomplete-->
                  <!--dense-->
                  <!--outlined-->
                  <!--v-model="filters.customer_user_id"-->
                  <!--:items="customersItem"-->
                  <!--item-text="customer_name"-->
                  <!--item-value="user_id"-->
                  <!--:search-input.sync="customarUserId"-->
                  <!--autocomplete="none"-->
                  <!--hide-no-data-->
                  <!--hide-selected-->
                  <!--:loading="loading2"-->
                  <!--style="width:200px;margin-top: 25px;"-->
                  <!--@change="customerSearch"-->
                  <!--&gt;</v-autocomplete>-->

                  <v-autocomplete
                    dense
                    outlined
                    v-model="filters.customer_user_id"
                    :items="customersItem"
                    item-text="customer_name"
                    item-value="user_id"
                    :search-input.sync="customarUserId"
                    :loading="loading2"
                    style="width: 200px; margin-top: 25px"
                    @change="customerSearch"
                    no-filter
                  >
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="
                              data.item.customer_name +
                              ' (' +
                              data.item.legacy_customer_id +
                              ')'
                            "
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.company_name"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.terminal"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.vessel"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="manualSearch"
                  ></v-text-field>
                </td>
                <td></td>
                <td></td>
                <td></td>

                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="search"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    :loading="filtering"
                    @click="clearFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr v-for="item in exports.data" :key="item.name">
                <td>
                  <manage-image
                    :lot="item.photos_count"
                    :text="item.thumbnail"
                    :id="item.id"
                  />
                </td>
                <td>{{ item.photos_count }}</td>
                <td>{{ item.vehicles_count }}</td>
                <td>{{ item.loading_date }}</td>
                <td>{{ item.export_date }}</td>
                <!-- <td>{{ item.eta }}</td> -->
                <td>
                  <manage-eta
                    class="manage-eta"
                    @dataUpdated="getData(pagination.current, filtersUrl())"
                    :text="item.eta"
                    :id="item.id"
                  />
                </td>
                <td>{{ item.booking_number }}</td>
                <td>
                  <a
                    v-if="item.container_tracking_url"
                    :href="item.container_tracking_url"
                    style="text-decoration: none"
                    target="_blank"
                  >
                    {{ item.container_number }}
                  </a>
                  <span v-else>
                    {{ item.container_number }}
                  </span>
                </td>
                <td>{{ item.container_type_name }}</td>
                <td>{{ item.port_of_loading_name }}</td>
                <td>{{ item.port_of_discharge_name }}</td>
                <td v-if="yardShow">
                  {{ item.vehicles[0].yard_name }}
                </td>
                <td>
                  <router-link
                    style="text-decoration: none"
                    :to="`/exports/${item.id}`"
                  >
                    {{ item.ar_number }}
                  </router-link>
                </td>
                <td>{{ item.status_name }}</td>

                <td>{{ item.manifest_date }}</td>
                <td>{{ item.customer_name }}</td>
                <td>{{ item.terminal }}</td>
                <td>{{ item.vessel }}</td>
                <td>
                  <v-icon
                    @click="
                      modalDetails({
                        email: `/exports/${item.id}/landing-pdf`,
                        openPdf: `/exports/${item.id}/landing-pdf`,
                        api: `exports/${item.id}/landing-modal`,
                      })
                    "
                    >mdi-file-document-multiple-outline
                  </v-icon>
                </td>
                <td @click="downLoadBl(item.id)">
                  <router-link style="text-decoration: none" to="">
                    BL
                  </router-link>
                </td>
                <td>
                  <v-icon
                    @click="
                      modalDetails({
                        email: `/exports/${item.id}/manifest-modal?email=1`,
                        openPdf: `/exports/${item.id}/manifest-pdf`,
                        api: `exports/${item.id}/manifest-modal`,
                      })
                    "
                    >mdi-file-document-multiple-outline
                  </v-icon>
                </td>

                <td style="min-width: 350px">
                  <div v-if="permissions['exports.view'] == false"></div>
                  <v-btn v-else text :to="`/exports/${item.id}`">
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                  <div v-if="permissions['exports.update'] == false"></div>
                  <v-btn v-else text @click="edit(item.id)" color="blue">
                    <v-icon>mdi-file-edit</v-icon>
                  </v-btn>
                  <div v-if="permissions['exports.destroy'] == false"></div>
                  <v-btn v-else text @click="destroy(item.id)" color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>

                  <a
                    v-if="item.export_invoice_photo"
                    :href="`${item.export_invoice_photo[0].url}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >USA INV</a
                  >

                  <a
                    v-if="item.dxb_inv"
                    :href="`${item.dxb_inv}`"
                    class="link-btn mr-1 mt-2"
                    target="_blank"
                    >DXB INV</a
                  >

                  <v-btn
                    text
                    @click="editHandOver(item.id, item.container_number)"
                    v-if="item.status === 6"
                  >
                    Hand Over
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="center" style="margin: 0">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <handover
      ref="handover"
      @dataUpdated="search()"
      :show="hand_over.dialog"
      :id="hand_over.vehicle_id"
      :container_no="hand_over.container_no"
      @cancelForm="hand_over.dialog = null"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Confirmation from "@/components/Confirmation.vue";
import downloadFile from "@/helpers/downloadFile";
import Api from "@/services/Api";
import Manage from "@/views/exports/Manage.vue";
import ManageEta from "@/views/exports/ManageEta.vue";
import Handover from "@/views/exports/ManageHandedOver.vue";
import ManageImage from "@/views/exports/ManageImage.vue";
import detailsModal from "@/views/exports/detailsModal.vue";
import { VueContentLoading } from "vue-content-loading";
export default {
  name: "Exports",
  components: {
    Confirmation,
    Manage,
    ManageEta,
    VueContentLoading,
    detailsModal,
    ManageImage,
    Handover,
  },
  data() {
    return {
      currentSort: "",
      currentSortDir: "asc",
      permissions: JSON.parse(localStorage.getItem("permissions")),
      editId: null,
      deletingId: null,
      formShow: false,
      deleteConfirmationShow: false,
      exports: {},
      pagination: {
        current: 1,
        total: 0,
      },
      filters: {},
      loading: false,
      filtering: false,
      statuses: [
        { id: "", title: "Select Status" },
        { id: 2, title: "Manifest" },
        { id: 4, title: "Shipped" },
        { id: 6, title: "Arrived" },
        { id: 7, title: "Handed Over" },
      ],
      containerData: [
        { id: "", title: "Select Container type" },
        { id: 1, title: "1 X 20'HC DRY VAN" },
        { id: 2, title: "1 X 45'HC DRY VAN" },
        { id: 3, title: "1 X 40'HC DRY VAN" },
      ],
      portLoadingData: [
        { id: "", title: "Select port of loading" },
        { id: 1, title: "NEW JERSEY, NWJ" },
        { id: 2, title: "HOUSTON, TX" },
        { id: 3, title: "LOS ANGELES, CA" },
        { id: 4, title: "NEWARK, NJ" },
        { id: 5, title: "SAVANNAH, GA" },
        { id: 6, title: "HOUSTON, TEXAS" },
        { id: 7, title: "HONOLULU, HI" },
        { id: 8, title: "BALTIMORE, MD" },
      ],
      portDischargeData: [
        { id: "", title: "Select port of discharge" },
        { id: 1, title: "JEBEL ALI, UAE" },
        { id: 2, title: "AQABA, JORDAN" },
        { id: 3, title: "KARACHI, PAKISTAN" },
        { id: 4, title: "SOHAR, OMAN" },
        { id: 5, title: "UMM QASR, IRAQ" },
        { id: 6, title: "MERSIN, TURKEY" },
        { id: 7, title: "CAMBODIA" },
        { id: 8, title: "BAHRAIN" },
        { id: 9, title: "MUSCAT,OMAN" },
        { id: 10, title: "FREETOWN, SIERRA LEONE" },
        { id: 11, title: "TEMA, GHANA" },
        { id: 12, title: "VIETMAN" },
        { id: 13, title: "BUSAN, KOREA" },
        { id: 14, title: "GERMANY" },
        { id: 15, title: "MISURATA, LIBYA" },
        { id: 16, title: "NIGERIA" },
        { id: 17, title: "DENMARK" },
        { id: 18, title: "BENGHAZI, LIBYA" },
        { id: 19, title: "MYANMAR" },
        { id: 20, title: "SALALAH, OMAN" },
        { id: 21, title: "LIBYA - ALKHOMS" },
        { id: 22, title: "BEIRUT, LEBANON" },
      ],
      updateDetals: false,
      details: {},
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      customersItem: [],
      yardsItem: [],
      loading2: false,
      customarUserId: "",
      userId: JSON.parse(localStorage.getItem("user")).id,
      downloading: false,
      hand_over: {
        dialog: false,
        vehicle_id: null,
        vin: "",
        date: null,
      },
      yardShow : false,
    };
  },
  methods: {
    Sort(currentsort) {
      this.loading = true;
      this.currentSortDir =
        this.currentSortDir === "asc" && this.currentSort === currentsort
          ? "desc"
          : "asc";
      this.currentSort = currentsort;

      this.filters.page = this.pagination.current;
      this.filters.order_by_column = currentsort;
      this.filters.order_by = this.currentSortDir;

      let totalSortFilterQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + this.filtersUrl();
      window.history.pushState({}, "", finalUrl);

      Api.get(`/exports?${totalSortFilterQuery}`)
        .then((res) => {
          this.exports = res.data;
          this.loading = false;
          this.yardShow = res?.data?.yard_show;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    getData(page, searchQuery = "") {
      this.loading = true;
      if (!page) {
        page = this.pagination.current;
      }

      this.filters.page = page;
      let finalSearchQuery = this.filtersUrl();

      let finalUrl = this.$route.path + `?` + finalSearchQuery;
      window.history.pushState({}, "", finalUrl);

      // if (searchQuery) {
      //   var searchQuery = `&${searchQuery}`;
      // }

      // Api.get(`/exports?page=${page}${searchQuery}`)
      Api.get(`/exports?${finalSearchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.exports = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
          this.yardShow = res?.data?.yard_show;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    getDataWithFilter(searchQuery) {
      Api.get(`/exports?${searchQuery}`)
        .then((res) => {
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.exports = res.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.loading = false;
          this.yardShow = res?.data?.yard_show;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });
    },
    loadingManage(value) {
      this.downloading = value;
    },
    exportContainer() {
      this.loadingManage(true);
      let url =
        Api.defaults.baseURL +
        `/exports/export-excel?auth_user_id=${this.userId}`;
      if (this.filtersUrl()) {
        var searchQuery = `?${this.filtersUrl()}`;
        url =
          Api.defaults.baseURL +
          `/exports/export-excel${searchQuery}&auth_user_id=${this.userId}`;
      }
      // window.location = url;
      downloadFile(url, "excel_export", ".xls", this.loadingManage);
    },

    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if(!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }

        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },

    search() {
      let page = this.filters.page ? this.filters.page : 1;
      this.getData(page, this.filtersUrl());
      this.hand_over.dialog = false;
    },
    manualSearch() {
      this.filters.page = 1;
      this.search();
    },
    downLoadBl(id) {
      window.open(
        `${process.env.VUE_APP_API_ENDPOINT}/api/v1/exports/${id}/landing-pdf`,
        "_blank"
      );
      // downloadFile(`${process.env.VUE_APP_API_ENDPOINT}/api/v1/exports/${id}/landing-pdf`,
      //     'customer_export',
      //     '.pdf',
      // );
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;

      this.$refs.manage.edit(id);
    },
    destroy(id) {
      this.deletingId = id;
      this.deleteConfirmationShow = true;
    },
    remove() {
      Api.delete(`/exports/${this.deletingId}`)
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });

      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
    modalDetails(e) {
      (this.updateDetals = !this.updateDetals), (this.details = e);
    },
    customerSearch() {
      this.search();
    },
    cusotmarAutoCompleteHandelar(newVal) {
      this.loading2 = true;
      Api.get(`/customers-item?status=1&customer_name=${newVal}`)
        .then((res) => {
          this.loading2 = false;
          this.customersItem = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading2 = false;
          }
        });
    },
    fetchEntriesDebounced(val) {
      // cancel pending call
      clearTimeout(this._timerId);

      // delay new call 500ms
      this._timerId = setTimeout(() => {
        this.cusotmarAutoCompleteHandelar(val);
      }, 700);
    },
    editHandOver(id, container_number) {
      this.hand_over.vehicle_id = id;
      this.hand_over.container_no = container_number;
      this.hand_over.dialog = true;
    },
  },
  mounted() {
    // console.log("gegghere sg");
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.mode && this.$route.query.mode === "openModal") {
        this.formShow = true;
      }
      this.filters = this.$route.query;

      if (this.filters.order_by_column) {
        this.currentSort = this.filters.order_by_column;
      }
      if (this.filters.order_by) {
        this.currentSortDir = this.filters.order_by;
      }
      this.getDataWithFilter(this.filtersUrl());
    } else {
      this.filters = {};
      this.getData(1);
    }

    Api.get(`/settings/yards?status=1`)
        .then((res) => {
          this.yardsItem = res.data.data;
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);

          }
        });

  },
  watch: {
    customarUserId(newVal) {
      // if (newVal) {
      //   this.loading2 = true;
      //   Api.get(`/customers-item?status=1&customer_name=${newVal}`)
      //     .then((res) => {
      //       this.loading2 = false;
      //       this.customersItem = res.data.data;
      //     })
      //     .catch((err) => {
      //       if (err.response.status !== 401) {
      //         this.$toastr.e("Failed to load data!" + err);
      //         this.loading2 = false;
      //       }
      //     });
      // }

      if (!newVal) {
        return;
      }

      this.fetchEntriesDebounced(newVal);
    },
    // "filters.container_type"() {
    //   this.search();
    // },
    // "filters.port_of_loading"() {
    //   this.search();
    // },
    // "filters.port_of_discharge"() {
    //   this.search();
    // },
    // "filters.status"() {
    //   this.search();
    // },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}

.date {
  min-width: 107px;
}

th.sortable:hover {
  color: black;
  text-decoration: underline;
}

.mdi.mdi-sort-descending,
.mdi.mdi-sort-ascending {
  float: right;
}

td.manage-eta:hover {
  text-decoration: underline;
}
</style>
