<template>
  <div class="home">
    <div>
      <vue-content-loading :width="300" :height="100" v-if="loading">
        <rect x="0" y="5" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="15" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="30" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="45" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="60" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="75" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="90" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="105" rx="4" ry="4" width="300" height="5" />
        <rect x="0" y="120" rx="4" ry="4" width="300" height="5" />
      </vue-content-loading>
      <div v-else class="bg-white mt-65 pt-5">
        <div class="d-flex" style="justify-content: space-between">
          <h1 class="primetime ml-5">Notes</h1>
          <div class="ml-auto mr-4 d-flex mt-2" cols="12" md="2">
            <h5>
              Showing : {{ this.showingFrom ? this.showingFrom : "0" }} -
              {{ this.showingTo ? this.showingTo : "0" }} of
              {{ this.count ? this.count : "0" }}
            </h5>
          </div>
        </div>

        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Damage Claim</th>
                <th class="text-left">Claim Status</th>
                <th class="text-left">REQ DATE</th>
                <th class="text-left">DELY DATE</th>
                <th class="text-left">YEAR</th>
                <th class="text-left">MAKE</th>
                <th class="text-left">MODEL</th>
                <th class="text-left">COLOR</th>
                <th class="text-left">VIN</th>
                <th class="text-left">LOT NO</th>
                <th class="text-left">KEYS</th>
                <th class="text-left">VEHICLE TYPE</th>
                <th class="text-left">TITLE</th>
                <th class="text-left">LOCATION</th>
                <th class="text-left" v-if="yardShow" >Yard</th>
                <th class="text-left">STATUS</th>
                <th class="text-left">Customer Name</th>
                <th class="text-left">Created Date</th>
                <th class="text-left">NOTE</th>
                <th class="text-left">SEARCH</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="text-left"></th>
                <td class="text-left">
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="damageClaim"
                    v-model="filters.damage_claim"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>

                <td class="text-left">
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="claimStatus"
                    v-model="filters.claim_status"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.title_received_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.deliver_date"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                    v-model="filters.year"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                    v-model="filters.make"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                    v-model="filters.model"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>

                <td>
                  <v-text-field
                    v-model="filters.color"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.vin"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="filters.lot_no"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="search"
                  ></v-text-field>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="keysItem"
                    v-model="filters.keys"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="vehicleItem"
                    v-model="filters.vehicle_type"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="titlesItem"
                    v-model="filters.title"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="locationsItem"
                    v-model="filters.location"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td v-if="yardShow">
                  <v-select
                    item-text="name"
                    item-value="id"
                    :items="yardsItem"
                    v-model="filters.yard_id"
                    outlined
                    dense
                    hide-details
                    style="width: 120px"
                  ></v-select>
                </td>
                <td>
                  <v-select
                    item-text="title"
                    item-value="id"
                    :items="statusItem"
                    v-model="filters.status"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </td>
                <td>
                  <v-autocomplete
                    dense
                    outlined
                    v-model="filters.customer_user_id"
                    :items="customersItem"
                    item-text="customer_name"
                    item-value="user_id"
                    :search-input.sync="customarUserId"
                    autocomplete="none"
                    hide-no-data
                    hide-selected
                    :loading="loading2"
                    style="width: 200px; margin-top: 25px"
                  ></v-autocomplete>
                </td>
                <td></td>
                <td>
                  <v-select
                      item-text="title"
                      item-value="id"
                      :items="notesItem"
                      v-model="filters.notes_status"
                      outlined
                      dense
                      hide-details
                  ></v-select>
                </td>
                <td>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="search"
                  >
                    <v-icon dark>mdi-clipboard-text-search</v-icon>
                  </v-btn>
                  <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="clearFilter"
                    v-if="isFilter"
                  >
                    <v-icon dark>mdi-close-octagon</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr v-for="(item, i) in notes" :key="item.id" :class="roleId === 3 ? item.cust_view : item.admin_view">
                <td>{{ i + 1 + (pagination.current - 1) * 20 }}</td>
                <td class="text-left">{{ item.vehicle.damage_claim }}</td>
                <td class="text-left">{{ item.vehicle.claim_status }}</td>
                <td class="text-left date">
                  {{ item.vehicle.title_received_date }}
                </td>
                <td class="text-left date">{{ item.vehicle.deliver_date }}</td>
                <td class="text-left">{{ item.vehicle.year }}</td>
                <td class="text-left">{{ item.vehicle.make }}</td>
                <td class="text-left">{{ item.vehicle.model }}</td>
                <td class="text-left">{{ item.vehicle.color }}</td>
                <td class="text-left">
                  <router-link
                      target="_blank"
                      style="text-decoration:none"
                      :to="`/vehicles/${item.vehicle.id}`"
                  >
                    {{ item.vehicle.vin }}
                  </router-link>
                </td>
                <td class="text-left">{{ item.vehicle.lot_number }}</td>
                <td class="text-left">{{ item.vehicle.keys_name }}</td>
                <td class="text-left">{{ item.vehicle.vehicle_type }}</td>
                <td class="text-left">{{ item.vehicle.title_type_name }}</td>
                <td class="text-left">{{ item.vehicle.location }}</td>
                <td class="text-left">{{ item.vehicle.yard_name }}</td>
                <td class="text-left">{{ item.vehicle.status_name }}</td>
                <td class="text-left">{{ item.vehicle.company_name }}</td>
                <td class="text-left date">{{ item.created_at }}</td>
                <td class="text-left">
                  <notes-modal
                      :text="item.vehicle.note_status.label"
                      :id="item.vehicle.id"
                      :vehicle_detail="{ customer_name: item.vehicle.customer_name, vin: item.vehicle.vin, lot_number: item.vehicle.lot_number}"
                      :status='item.vehicle.note_status.value'
                      :color='item.vehicle.note_status.class'
                      @notestoggle="notesShow = !notesShow"
                      :show="notesShow"
                      @dataupdate='getData()'
                  />
                </td>
                <td>
                  <a
                      target="_blank"
                      :href="
                    `https://www.google.com/search?tbm=isch&as_q=${item.vehicle.year}++${item.vehicle.model}++${item.vehicle.make}++${item.vehicle.color}`
                  "
                  >Search</a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-row justify="center" style="margin: 0;">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                class="my-4"
                :total-visible="7"
                circle
                @input="getData(pagination.current, filtersUrl())"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </div>

    <div>
      <v-dialog v-model="statusConfirm" persistent max-width="500">
        <v-card>
          <v-card-title class="headline">
            Are you sure want to continue this action?
          </v-card-title>
          <v-card-text
            >If you confirm this action it can't be undo!</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="statusConfirm = false">
              Cancel
            </v-btn>
            <v-btn color="green darken-1" text @click="destroy(statusId)">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Api from "@/services/Api";
import { VueContentLoading } from "vue-content-loading";
import Manage from "@/views/notifications/Manage";
import NotesModal from "@/components/vehicle/NotesModal.vue";
export default {
  name: "roles",
  components: { VueContentLoading, Manage, NotesModal },
  data() {
    return {
      roleId: JSON.parse(localStorage.getItem("user")).role,
      editId: null,
      deletingId: null,
      formShow: false,
      notesShow: false,
      notes: {},
      statusConfirm: false,
      filters: {},
      statusId: null,
      loading: false,
      loading2: false,
      customarUserId: "",
      isActive: true,
      pagination: {
        current: 1,
        total: 0,
      },
      keysItem: [
        { title: "", id: "" },
        { title: "Yes", id: "1" },
        { title: "No", id: "0" },
      ],
      locationsItem: [
        { title: "", id: "" },
        { title: "LA", id: 1 },
        { title: "LA", id: 1 },
        { title: "GA", id: 2 },
        { title: "NY", id: 3 },
        { title: "TX", id: 4 },
        { title: "BALTIMORE", id: 5 },
        { title: "NEWJ-2", id: 6 },
        { title: "TEXAS", id: 7 },
        { title: "NJ", id: 8 },
      ],
      titlesItem: [
        { title: "", id: "" },
        { title: "NO TITLE", id: 0 },
        { title: "EXPORTABLE", id: 1 },
        { title: "PENDING", id: 2 },
        { title: "BOS", id: 3 },
        { title: "LIEN", id: 4 },
        { title: "MV907", id: 5 },
        { title: "REJECTED", id: 6 },
      ],
      statusItem: [
        { title: "", id: "" },
        { title: "ON HAND", id: 1 },
        { title: "MANIFEST", id: 2 },
        { title: "ON THE WAY", id: 3 },
        { title: "SHIPPED", id: 4 },
        { title: "PICKED UP", id: 5 },
        { title: "ARRIVED", id: 6 },
      ],
      claimStatus: [
        { title: "" },
        { title: "REJECTED", id: "0" },
        { title: "APPROVED", id: 1 },
        { title: "PENDING", id: 10 },
      ],
      damageClaim: [
        { title: "", id: "" },
        { title: "YES", id: 1 },
        { title: "NO", id: "0" },
      ],
      customersItem: [],
      loadingTypesItem: [
        { title: "", id: "" },
        { title: "Full", id: 1 },
        { title: "Mix", id: 2 },
      ],
      notesItem: [
        { title: "", id: "" },
        { title: "Open", id: 2 },
        { title: "Closed", id: 1 },
      ],
      vehicleItem: [
        { title: "", id: "" },
        { title: "Sedan", id: "Sedan" },
        { title: "Van", id: "Van" },
        { title: "Pickup", id: "Pickup" },
        { title: "Truck", id: "Truck" },
        { title: "Mortorcycle", id: "Mortorcycle" },
      ],
      yardsItem : [],
      showingFrom: 0,
      showingTo: 0,
      count: 0,
      yardShow: false,
    };
  },
  methods: {
    getData(page, searchQuery = "") {
      if (!page) {
        page = this.pagination.current;
      }
      this.loading = true;
      if (searchQuery) {
        searchQuery = `&${searchQuery}`;
      }
      // searchQuery += `&unread_only=false`
      Api.get(`/vehicles/notes?page=${page}${searchQuery}`)
        .then((res) => {
          this.notes = res.data.data;
          this.pagination.current = res.data.current_page;
          this.pagination.total = res.data.last_page;
          this.pagination.per_page = res.data.per_page;
          this.loading = false;
          this.showingFrom = res.data.from;
          this.showingTo = res.data.to;
          this.count = res.data.total;
          this.yardShow = res.data.yard_show;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            // this.loading = false;}
          } else {
            this.$toastr.e(err.response.data.status);
          }
        });

      this.editId = null;
      this.deletingId = null;
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (!this.filters[key]) {
          continue;
        }
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    edit(id) {
      this.editId = id;
      this.formShow = true;
      this.$refs.manage.edit(id);
    },

    destroy() {
      Api.delete(`/vehicles/notes/${this.deletingId}`)
        .then((res) => {
          this.getData();
          this.$toastr.s(res.data.message);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.$toastr.e("Failed to load data!" + err);
            this.loading = false;
          }
        });
      this.statusConfirm = false;
      this.editId = null;
      this.deletingId = null;
      this.formShow = false;
    },
    filtersUrl() {
      var str = "";
      for (var key in this.filters) {
        if (str != "") {
          str += "&";
        }
        str += key + "=" + encodeURIComponent(this.filters[key]);
      }
      return str;
    },
    search() {
      // this.filtering = true;
      this.getData(1, this.filtersUrl());
    },
    clearFilter() {
      this.filters = {};
      this.getData();
    },
  },
  computed: {
    isFilter() {
      if (Object.keys(this.filters).length) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getData();
    this.yardsItem = this.$store.state.auth.yards;
  },
  watch: {
    customarUserId(newVal) {
      if (newVal) {
        this.loading2 = true;
        Api.get(`/customers-item?status=1&customer_name=${newVal}`)
          .then((res) => {
            this.loading2 = false;
            this.customersItem = res.data.data;
          })
          .catch((err) => {
            this.$toastr.e("Failed to load data!" + err);
            this.loading2 = false;
          });
      }
    },
    "filters.damage_claim"() {
      this.search();
    },
    "filters.claim_status"() {
      this.search();
    },
    "filters.keys"() {
      this.search();
    },
    "filters.title"() {
      this.search();
    },
    "filters.location"() {
      this.search();
    },
    "filters.yard_id"() {
      this.search();
    },
    "filters.status"() {
      this.search();
    },
    "filters.loading_type"() {
      this.search();
    },
    "filters.notes_status"() {
      this.search();
    },
    "filters.vehicle_type"() {
      this.search();
    },
    "filters.customer_user_id"() {
      this.search();
    },
  },
};
</script>
<style scoped>
.home {
  background-color: #f8f8fb;
}
.date {
  width: 90px;
}
.admin_read {
  background-color: #f3f3f3;
}
.admin_unread {
  background-color: white;
  font-size: 14px;
  font-weight: bold;
}
.cust_read {
  background-color: #f3f3f3;
}
.cust_unread {
  background-color: white;
}
</style>
